import React, {useState} from 'react'
import Mission from './Components/Mission'
import Vision from './Components/Vision'
import VisionBefore from './VisionBefore'

const Slider = () => {
    let slideArray = [<VisionBefore />, <Mission />]
    const [x, setX] = useState(0)
    const Left = () => {
        x === 0 ? setX(-100*(slideArray.length-1)) : setX(x+100)
    }
    const Right = () => {
        x === -100*(slideArray.length-1) ? setX(0) : setX(x-100)
    }
    return (
        <div id="#" className="slider">
            {
               slideArray.map((item, index) => {
                    return(
                        <div key={index} className="slideArray" style={{transform:`translateX(${x}%)`}}>
                            {item}
                        </div>
                    )
                })
            }
            <button id="btnLHS" className="btnStyles" onClick={Left}>
                <img src="../rhsArrow.jpg" alt="Button" className="rhsArrow"/>
            </button>
            <button id="btnRHS" className="btnStyles" onClick={Right}>
                <img src="../lhsArrow.jpg" alt="Button" className="lhsArrow"/>
            </button>
        </div>
    )
}

export default Slider
