import React from 'react';

const AboutUs = () => {
  return (
    <div className="aboutus">
      <h1 className="honeAU">About Us</h1>
      <p className="pAU">We are developing a self-sustained, transparent, data driven and result oriented platform to provide right education to underserved children of India. We are committed to serve the nation by collaborating with schools, changemakers, government, entrepreneurs etc. We would like to collaborate with global foundations such as Teach for India, Tata Institute of social science, Bill and Melinda gates foundation, Infosys foundation, Azim Premji Foundation, Shivnadar Foundation etc. for various programs.</p>
    </div>
  );
}

export default AboutUs;