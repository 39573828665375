import React, {useState} from 'react';
import {db} from '../firebase.js'

const ContactUs = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
 
  const [loader, setLoader] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)

    db.collection("contacts").add({
      name: name,
      email: email,
      phone: phone,
      message: message,
    })
    .then(() => {
      alert("Message has been submitted.")
      setLoader(false)
    })
    .catch((error) => {
      alert(error.message)
      setLoader(false)
    })

    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
  }

  return (
    <div id="contactus" className="contactus">
      <div className="gridCU">
        <div className="gridCUgrid">
          <h1>Contact Us</h1><br/>
          <form className="divFormCU" onSubmit={handleSubmit}>
            <input 
              type="text" placeholder=" Name" className="formCU" 
              value={name}
              onChange={(e) => setName(e.target.value)}
            /><br/>
            <input 
              type="text" placeholder=" Email Address" className="formCU" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /><br/>
            <input
              type="text" placeholder=" Phone Number" className="formCU" 
              value={phone}
              onChange={(e) => setPhone(e.target.value)} 
            /><br/>
            <input 
              type="text" placeholder=" Write a message" className="formCU" 
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            /><br/>
            <button 
              type="submit"
              className="formCUb formCU"
              style={{background: loader ? "#ccc" : "#fff "}}  
            >Submit</button>
          </form>
        </div>
        <div className="gridCUgrid">
          <p className="quoteCU">“Let’s serve together for a better world”</p>
        </div>
        <br/>
      </div>
    </div>
  );
}

export default ContactUs;