import React from 'react';
import {Route, Switch, NavLink, BrowserRouter as Router} from 'react-router-dom';
import Two from '../two';

const Initiatives = () => {
    return (
        <div>
          <div className="initiative">
            <div className="gridConatiner">
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text></div>
              </a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text></div>
              </a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
              <a href="#" className="btn-1">
                <div className="nameIni"><img src="logo192.png" alt="" className="imgIni" />
                <text className="textImgOne">Name</text>
                </div></a>
            </div>
          </div>
          
        </div>
    );
}

export default Initiatives;