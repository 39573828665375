import React from 'react'

function ScrollCU(props) {
  return (
    <div className="nav-item" id={props.id}>
      <a href={props.link} className="spnavbarlink" onClick={props.onClick}>
        {props.title}
      </a>
    </div>
  );
}

export default ScrollCU
