import React from 'react';
import { BrowserRouter as Router, NavLink, Route } from 'react-router-dom';

const Vision = () => {
  return (
      <div className="vision">
        <div className="gridOne">
          <p className="spanVision">Impact of Giving</p>
        </div>    
        <div className="gridOne forMargin">
          <ul className="ulul">
          <div className="forHori divone">
              <div className="forspace">
                <li>
                  <p className="upperPart">12</p>
                  <p className="lowerPart">Students</p>
                </li>
              </div>
              <div className="forspace">
                <li>
                  <p className="upperPart">4</p>
                  <p className="lowerPart">Partner&nbsp;Schools</p>
                </li>
              </div>
            </div>
            <div className="forHori divtwo">
              <div className="forspace">
                <li>
                  <p className="upperPart">3</p>
                  <p className="lowerPart">Partner&nbsp;NGOs</p>
                </li>
              </div>
              <div className="forspace">
                <li>
                  <p className="upperPart">1</p>
                  <p className="lowerPart">Program</p>
                </li>
              </div>
            </div>
            <div className="divthree">
              <li>
                <p className="upperPart">1,20,000&nbsp;INR</p>
                <p className="lowerPart">Raised for Programs</p>
              </li>
            </div>
          </ul>
        </div>
      </div> 
  );
}

export default Vision;