import firebase from 'firebase'

  var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCOsfkklKLyLtRkuVdiBauHq-C7Xn0m63w",
    authDomain: "nand-react-form.firebaseapp.com",
    projectId: "nand-react-form",
    storageBucket: "nand-react-form.appspot.com",
    messagingSenderId: "806367838422",
    appId: "1:806367838422:web:a35aff33ba576c12974977",
    measurementId: "G-ZG746EDP8F"
  })

var db = firebase.firestore();

export {db}