import React from 'react'

const InitiativesMain = () => {
    return (
        <div id="initiative" className="InitiativesMain">
          <h1 className="honeIni">Initiatives</h1> 
          <div className="gridPilot"> 
            <div className="gridPilotgrid">
              <p className="pIni">Pilot Project</p>
              <p className="spanPilotLine"><h2>“Send a student to school”</h2></p>
            </div>
            <div className="gridPilottwo">
              <img src="../../pilotpro.jpg" className="imgInitiativeClass" />
            </div>
          </div>
        </div>
    )
}

export default InitiativesMain
