import React from 'react'

const VisionBefore = () => {
    return (
        <div className="visionBeforeColor">
            <div>
                <img src="./BeforeVision.png" alt="image" className="imgMission" />
            </div>
            <div>
                <text className="textMission">
                    <span className="spanOnee">Vision<br/></span>
                    <span className="spanTwoo">Development of self-sustained, transparent, data driven and result oriented platform to provide right education to underserved children of India.</span><br/>
                </text>
            </div>
        </div>
    )
}

export default VisionBefore
