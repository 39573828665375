import React, {useRef} from 'react';
import './App.css';
import './AppMedia.css';
import Nav from './Nav';
import { BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';
import Slider from './Slider';
import Vision from './Components/Vision';
import Initiatives from './Components/Initiatives';
import InitiativesMain from './Components/InitiativesMain';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';

const App = () => {
  return (
    <div>
        <Nav />
        <Slider />
        <Vision />
        <InitiativesMain />
        <Initiatives />
        <AboutUs />
        <ContactUs />
        <Footer />
    </div>
  );
}



export default App;
