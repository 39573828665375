import React from 'react';
import ContactUs from './Components/ContactUs';
import ScrollCU from './Components/ScrollCU';

const Nav = () => {
  const homeSection = () => 
    alert('Hello!');

  return (
    <div className="navSec">
      <nav>
          <a href="#"><img src="./logo.png" alt="" className="navLogo"/></a>
          <ul className="navLinks"> 
            <a href="#" className="btnNav">Home</a>
            <a href="#initiative" className="btnNav">Initiatives</a>
            <a href="#contactus" className="btnNav">Contact Us</a>
          </ul>
      </nav>
    </div>
  );
}

export default Nav;
