import React from 'react';

const Mission = () => {
  return (
      <div className="mission">
        <div>
          <img src="../../Mission.png" alt="image" className="imgMission" />
        </div>
        <div>
          <text className="textMission">
            <span className="spanOne">Mission<br/></span>
            <span className="spanTwo">Every child shall get education.</span><br/>
          </text>
        </div>
      </div> 
  );
}

export default Mission;