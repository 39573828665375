import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Footer = () => {
    return (
        <div className="footerClass">
            <div className="footerLine"></div>
            <div className="footerCR">
                Copyright © 2020 All Rights Reserved by NandFoundation
            </div>
        </div>
    )
}

export default Footer
